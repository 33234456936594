module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="resourceContainer" class="resourceContainer">\n  <div id="clusterBar" class="resourceTitleBar">\n    <div class="resourceContainerLabel">\n    ';
 if (hasAWSCredential) { ;
__p += '<a href="' +
((__t = ( legacyURL )) == null ? '' : __t) +
'/cluster/list">';
 } ;
__p += '\n      <span class="visually-hidden">\n        ';
 if(hasAzureAndAWSCredentials && legacyClusterEnabled) { ;
__p += '\n          ' +
((__t = ( i18n.computeResourcePageAWSClusterLabel )) == null ? '' : __t) +
'\n        ';
 } else {
          if (hasAWSCredential) { ;
__p += '\n            ' +
((__t = ( i18n.computeResourcePageClusterLabel )) == null ? '' : __t) +
'\n          ';
 } else { ;
__p += '\n            ' +
((__t = ( i18n.computeResourcePageClusterNoCreds )) == null ? '' : __t) +
'\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </span>\n      <span>';
 if(hasAzureAndAWSCredentials && legacyClusterEnabled) { ;
__p += '\n          ' +
((__t = ( i18n.computeResourcePageAWSClusterLabel )) == null ? '' : __t) +
'\n        ';
 } else {
          if (hasAWSCredential) { ;
__p += '\n            ' +
((__t = ( i18n.computeResourcePageClusterLabel )) == null ? '' : __t) +
'\n          ';
 } else { ;
__p += '\n            ' +
((__t = ( i18n.computeResourcePageClusterNoCreds )) == null ? '' : __t) +
'\n          ';
 } ;
__p += '\n        ';
 } ;
__p += '\n      </span>\n    ';
 if (hasAWSCredential) { ;
__p += '</a>';
 } ;
__p += '</div>\n    <div class="dropdown menu-container createNewComputeResource">\n      <button id="startNewClusterButton" title="' +
((__t = ( i18n.createClusterButtonTooltip )) == null ? '' : __t) +
'" type="button" disabled="disabled" class="disabled btn btn_color_blue companion_btn startNewMatlabButton" aria-haspopup="true" aria-expanded="false">\n        <span class="icon-add"></span>\n        <span>' +
((__t = ( i18n.computeResourcePageCreateNewClusterLabel )) == null ? '' : __t) +
'</span>\n      </button>\n    </div>\n  </div>\n  <div class="noClusterMessageContainer" style="display: none;">\n    <div class="noDataMsg"><span>' +
((__t = ( i18n.computeResourcePageNoClustersFound )) == null ? '' : __t) +
'</span></div>\n  </div>\n  <div class="clusterContainer">\n    <section>\n      <ol id="cluster-resource-collection" class="collection collection-container">\n          <div class="progressSpinnerContainer">\n            <mw-progress-indicator type="spinner" size="small"></mw-progress-indicator>\n          </div>\n        </ol>\n    </section>\n  </div>\n</div>\n';

}
return __p
};
